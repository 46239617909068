import React from "react"

import Instagram from "../images/icons/instagram.svg"
import Twitch from "../images/icons/twitch.svg"
import Twitter from "../images/icons/twitter.svg"
import Github from "../images/icons/github.svg"

export default () => (
    <div className="flex mt-5">
        <a href="https://twitter.com/FabioRosado_" className="mr-5"> <img src={Twitter} alt="Twitter" /></a>
        <a href="https://twitch.tv/theflyingdev" className="mr-5"><img src={Twitch} alt="Twitch" /></a>
        <a href="https://github.com/FabioRosado" className="mr-5"><img src={Github} alt="Github"/></a>
        <a href="https://www.instagram.com/theflyingdev/" className="mr-5"><img src={Instagram} alt="Instagram" /></a>
    </div>
)